import React from "react"

// Components
import Layout from "../../../components/layout"
import Band from "../../../components/band"
import SEO from "../../../components/seo"
import FlowDataTableTopsNav from "../../../components/top-nav/flow-top-nav"

// Files
import FaqPdfSrc from "../../../files/Flow Data Table FAQ.pdf"

const FlowDataTableFaqPage = () => (
  <Layout>
    <SEO title="Flow Data Table" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `flow data table`, `faqs`]} />

    <FlowDataTableTopsNav />

    <Band>
      <h1>FAQ</h1>
      <a href={FaqPdfSrc} target="_blank" rel="noopener noreferrer">View PDF</a>
    </Band>
  </Layout>
);

export default FlowDataTableFaqPage;
